import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required, requiredIf, minLength } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'
import Vue from 'vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'create'
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      employee_code: { required, minLength: minLength(4) },
      password: { required: requiredIf(function() {
        return this.mode === 'create'
      }), minLength: minLength(4) },
      firstname: { required },
      lastname: { required },
      gender: { required },
      phone_number: { required },
      position: { required },
      branch_id: { required }
    },
  },
  data() {
    return {
      role: "",
      branch: "",
      branches: [],
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      inputType: 'file',
      fileError: '',
      file: null,
      form: {
        _id: null,
        image: null,
        employee_code: "",
        password: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        email_address: "",
        line: "",
        facebook: "",
        note: "",
        gender: null,
        position: null,
        branch_id: null,
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
        if(this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
      this.form.branch_id = (res.data.branch_id !== "0" && res.data.branch_id) ? res.data.branch_id : null
    },
    resetForm () {
      this.form._id = null
      this.form.image = null
      this.form.employee_code = null
      this.form.password = null
      this.form.firstname = null
      this.form.lastname = null
      this.form.phone_number = null
      this.form.email_address = null
      this.form.line = null
      this.form.facebook = null,
      this.form.note = null,
      this.form.gender = null,
      this.form.position = null  
      if(this.role ==='Admin') this.form.branch_id = null   

      this.$nextTick(() => {
        this.$v.form.$reset()
      })      
      this.onResetFileInput()
    },
    formBuilder(obj) {
      this.form._id = obj.data?.uuid ?? ''
      this.form.employee_code = obj.data?.employee_code ?? ''
      this.form.password = obj.data?.password ?? ''
      this.form.firstname = obj.data?.firstname ?? ''
      this.form.lastname = obj.data?.lastname ?? ''
      this.form.phone_number = obj.data?.phone_number ?? ''
      this.form.email_address = obj.data?.email_address ?? ''
      this.form.line = obj.data?.line ?? ''
      this.form.facebook = obj.data?.facebook ?? ''
      this.form.note = obj.data?.note ?? ''
      this.form.gender = obj.data?.gender ?? ''
      this.form.position = obj.data?.role ?? ''
      this.form.branch_id = obj.data?.branch_id ?? ''
      this.$v.form.$touch()
      if (this.mode === 'update') {
        this.$v.form.password.$reset()
      }
    },
    onFileHandler(e) {
      this.$refs.fileInput.click()
    },
    onResetFileInput() {
      this.inputType = 'text'      
      this.$nextTick(() => {
        this.fileError = ''
        this.file = null
        this.inputType = 'file'    
      })
    },
    onFileChange(e) {
      if (e.target.files.length > 0) { 
        this.fileError = ''
        const selectedFile = e.target.files[0]
        // File type validation (accept only image files)
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if (!allowedFileTypes.includes(selectedFile.type)) {
          this.fileError = 'ไฟล์รูปภาพไม่ถูกต้อง. กรุณาเลิอกไฟล์รูปภาพ.'
          return;
        } 
        
        this.onFIletoBase64(selectedFile).then(file => {
          this.file = file
          this.form.image = file
        }).catch(error => {
          this.onExceptionHandler(error.message)
        })
      }      
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onInitData() {
      try {
        if (this.id) {
          this.loading = true
          const param = {
            _id: this.id
          }
          const result = await this.$store.dispatch('Users/get', param, { root: true })
          setTimeout(() => {
            this.loading = false
            if (result.status === 200) {
              this.formBuilder(result)
            }
          }, 500)
        }
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async getBranches() {
      // const result = await this.$store.dispatch('Branches/getAll', null, { root: true })
      // this.branches = result.data.data

      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.branches = result.data
    },
    onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        if (this.form.image) {
          formData.append('image', this.form.image)
        }
        formData.append("employee_code", this.form.employee_code)
        formData.append("password", this.form.password)
        formData.append("firstname", this.form.firstname)
        formData.append("lastname", this.form.lastname)
        formData.append("gender", this.form.gender)
        formData.append("phone_number", this.form.phone_number)
        formData.append("email_address", this.form.email_address)
        formData.append("line", this.form.line)
        formData.append("facebook", this.form.facebook)
        formData.append("gender", this.form.gender)
        formData.append("role", this.form.position)
        formData.append("note", this.form.note)
        formData.append("branch_id", this.form.branch_id)
  
        setTimeout(async() => {
          let result = {}
          if (this.mode === 'create') {
            result = await this.$store.dispatch('Users/create', formData, { root: true })
          } else {
            const body = {
              _id: this.id,
              formData: formData
            }
            result = await this.$store.dispatch('Users/update', body, { root: true })
          }
          this.loading = false
          if (result.status === 201 || result.status === 200) {
            this.resetForm()
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.onCloseDrawer()
            })
          } else {
            this.onExceptionHandler(result.data.message)
          }
          this.mode = 'create'
        }, 500)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  },
  created(){
    this.getBranches()
    this.getRole()
  }
}